import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PrivacyPolicy } from '@b3w/pp-portal';
import { textAPI } from 'api';
import { getSafeString } from 'utils/getSafeString';
import Pages from 'pages';
import { useChat } from 'context/ChatContext';
import { useApp } from 'context/AppContext';
import { Header } from 'components/Header/Header';
import { Menu } from 'components/Menu/Menu';
import ModalApp from 'components/ModalApp/ModalApp';
import { LandscapeScreen } from 'components/LandscapeScreen/LandscapeScreen';
import { Approve, APPROVE_ROUTE } from '../pages/Approve/Approve';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Component } from './App.styled';
import { Path } from "../routes";

const App = () => {
    const { pathname } = useLocation();
    const { data, isLoading, isSuccess } = textAPI.useGetPolicyQuery();
    const content = getSafeString(data?.content);

    const { events } = useChat();
    const { updateMessageCount } = useApp();

    const [isLandscapeOrientation, setLandscapeOrientation] = useState(false);

    const currentRoute = useLocation().pathname;
    const isCookiePage = currentRoute === Path.public.cookies;

    // For more information see component <Approve/>
    if (pathname === APPROVE_ROUTE) {
        return (
            <Component id="app">
                <Approve/>
            </Component>
        );
    }

    window.addEventListener('orientationchange', (ev) => {
        if (window.screen.orientation.type === 'landscape-primary') {
            setLandscapeOrientation(true);
        } else {
            setLandscapeOrientation(false);
        }
    });

    useEffect(() => {
        if (events.length) {
            updateMessageCount();
        }
    }, [events]);

    return (
        <Component id="app">
            { isLandscapeOrientation && <LandscapeScreen /> }
            <Header/>
            <Menu/>
            <Pages/>
            <ModalApp/>
            {/*// @ts-ignore*/}
            { !isLoading && isSuccess && !isCookiePage &&
                <PrivacyPolicy
                    dangerousText={content}
                    adoption
                    maxWidth={560}
                    btnNameAccept={'Принять'}
                    btnNameDecline={'Отклонить'}
                    settingsRout={Path.public.set_cookies}
                /> }
        </Component>
    )
};

export default App;
